<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>文章留言</span>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂无更多"
        @load="onLoad"
      >
        <div class="article-list-content" v-for="item in list" :key="item.id">
          <ArticleItem v-if="!$store.state.user.elderModel" :item="item" />
           <ArticleItemEler v-else :item="item" />
        </div>
      </van-list>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/layout.vue'
import ArticleItem from '@/views/comprehensive/article-item.vue'
import ArticleItemEler from '@/views/comprehensive/articleItem-elder.vue'
import { getMyleave } from '@/api/me'
export default {
  components: {
    layout,
    ArticleItem,
    ArticleItemEler
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isLoading: false,
      refreshSuccessText: '刷新成功',
      pageNo: 1,
      pageSize: 10
    }
  },
  created() {},
  methods: {
    async onLoad() {
      try {
        const result = await getMyleave({
          pageNo: this.pageNo,
          pageSize: this.pageSize
        })
        const list = result.result.records
        list.forEach((item) => {
          item.frontCover = item.frontCover.split(',')
        })
        this.list.push(...list)
        this.loading = false
        if (result.result.records.length) {
          this.pageNo++
        } else {
          this.finished = true
        }
      } catch (error) {
        this.loading = false
      }
    },
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}
</style>